import React, { useMemo } from "react"
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core"
import { graphql, Link } from "gatsby"

import { sortBy, flatten, min } from "lodash"

import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { ProductItemCard } from "../components/ProductItemCard"
import { SearchInput } from "../components/SearchInput"
import { IndexPageDataQuery } from "./__generated__/IndexPageDataQuery"
import { useBatchLoading } from "../useBatchLoading"
import InfiniteScroll from "react-infinite-scroller"

import LOGO from "../images/Logo.svg"

interface Props {
  data: IndexPageDataQuery
}

const useStyles = makeStyles({
  stylesLink: {
    textDecoration: "none",
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  stylesContainer: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.04)",
    },
  },
})

const IndexPage = ({ data }: Props) => {
  const sortedData = useMemo(
    () =>
      sortBy(data.hasura.products, product =>
        min(
          flatten(
            product.listings.map(listing =>
              listing.deals.map(deals => deals.price_change_percent)
            )
          )
        )
      ),
    [data]
  )

  const [visibleData, loadMore, hasMore] = useBatchLoading(sortedData || [], {
    initialSize: 10,
    loadSize: 15,
  })

  const loader = (
    <Box
      key="loader-key"
      mt={8}
      display="flex"
      alignContent="center"
      justifyContent="center"
    >
      <CircularProgress></CircularProgress>
    </Box>
  )

  const classes = useStyles()

  return (
    <Layout showHeader={false}>
      <SEO title="The Best Whisky Deals" />
      <Box>
        <Box
          display="flex"
          alignContent="center"
          justifyContent="center"
          minHeight="400px"
          flexDirection="column"
        >
          <Box display="flex" alignContent="center" justifyContent="center">
            <img
              className={classes.logo}
              src={LOGO}
              alt="The Liquor Project Logo"
            />
          </Box>
          <Box fontFamily="Roboto Condensed" textAlign="center" mt={2}>
            <Typography variant="body1">
              Discover the best of the world of liquor
            </Typography>
          </Box>
          <Box
            width="100%"
            display="flex"
            alignContent="center"
            justifyContent="center"
          >
            <Box width={{ xs: "100%", sm: "100%", md: "80%" }} mt={4}>
              <SearchInput></SearchInput>
            </Box>
          </Box>
        </Box>
        <Box mt={5} mb={1}>
          <Typography variant="h5" component="h2">
            Popular Whisky Styles
          </Typography>
        </Box>
        <Box mt={1} mb={2}>
          <Divider />
        </Box>
        <Box>
          <Grid container spacing={1}>
            {data.hasura.tags_occurrence.map(tag => (
              <Grid key={tag.value} item xs={6} sm={4}>
                <Link
                  className={classes.stylesLink}
                  to={`whisky/style/${tag.value}`}
                >
                  <Paper className={classes.stylesContainer} key={tag.value}>
                    <Box p={1}>
                      <Typography variant="body1">{tag.value}</Typography>
                    </Box>
                  </Paper>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mt={5}>
          <Box>
            <Typography variant="h5" component="h1">
              Whisky Deals
            </Typography>
          </Box>
          <Box mt={1} mb={3}>
            <Typography variant="body2">
              We monitor the web for the price changes in thousands of products
              to find the best bargains available. The percentage shows how the
              lowest price has changed, not the change in a specific shop.
              Always check the price history and product description yourself.
            </Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={hasMore}
              loader={loader}
            >
              <Grid container spacing={1} direction="row">
                {visibleData.map(product => (
                  <Grid md={4} xs={12} key={product.id} item>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <ProductItemCard product={product} />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageDataQuery {
    hasura {
      tags_occurrence(where: { type: { _eq: "style" } }) {
        value
      }

      products(
        where: {
          listings: { deals: {}, last_available_at: { _is_null: false } }
        }
      ) {
        id
        ...ProductItemCardFragment
      }
    }
  }
`
