import React from "react"

export function useBatchLoading<T>(
  values: T[],
  opts: { loadSize: number; initialSize: number }
): [T[], () => void, boolean] {
  const { loadSize, initialSize } = opts
  const [offset, setOffset] = React.useState(initialSize)

  // Reset the loading when the items change
  React.useEffect(() => {
    if (offset !== initialSize) setOffset(initialSize)
  }, [values])

  const loadMore = () => {
    setOffset(offset + loadSize)
  }

  if (values.length <= offset) return [values, () => {}, false]

  return [values.slice(0, offset), loadMore, true]
}
